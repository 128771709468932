<template>
	<Page ref="page">
		<template slot="search">
			<el-form inline :model="params" ref="filtersForm">
				<el-form-item label="序列号" prop="sn">
					<el-select
						v-model="params.sn"
						filterable
						remote
						reserve-keyword
						placeholder="请输入序列号"
						:remote-method="listSn"
						:loading="loading"
					>
						<el-option
							v-for="item in options"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="是否可见" prop="showOrNot">
					<el-select v-model="params.showOrNot" placeholder="请选择" clearable>
					    <el-option label="可见" value="1" />
						<el-option label="隐藏" value="0" />
					  </el-select>
				</el-form-item>
			</el-form>
		</template>
		<template slot="otherBtn">
		  <el-button type="primary" plain @click="addEvent" v-auth="'add'">
		    添加
		  </el-button>
		</template>

		<el-table
			:data="tableData"
			style="margin-bottom: 40px; width: 100%"
			row-key="code"
			border
			v-loading="loading"
			:row-class-name="tableRowClassName"
		>
			<el-table-column width="50" />
			<el-table-column
				align="center"
				label="序号"
				type="index"
				width="60"
			/>
			<el-table-column
				show-overflow-tooltip
				prop="code"
				label="子件料号"
				width="230"
			/>
			<el-table-column
				show-overflow-tooltip
				prop="name"
				label="子件名称"
			/>
			<el-table-column
				show-overflow-tooltip
				prop="spec"
				label="子件规格"
			/>
			<el-table-column
				header-align="center"
				align="center"
				show-overflow-tooltip
				label="是否可见"
				width="80"
			>
				<template slot-scope="{ row }">
					{{ showFilter(row.showOrNot) }}
				</template>
			</el-table-column>
			<el-table-column
				header-align="center"
				align="center"
				show-overflow-tooltip
				label="状态"
				width="80"
			>
				<template slot-scope="{ row }">
					{{ statusFilter(row.status) }}
				</template>
			</el-table-column>
			<el-table-column
				header-align="center"
				align="center"
				show-overflow-tooltip
				label="创建时间"
				width="160"
				prop="makeDate"
			/>
			<el-table-column
			  header-align="center"
			  align="center"
			  show-overflow-tooltip
			  label="操作"
			  width="80"
			>
			  <template slot-scope="{ row }">
			    <el-button
			      size="medium"
			      type="text"
			      v-auth="'delete'"
			      @click="delEvent(row.id)"
				  v-if="row.increment"
			    >
			      删除
			    </el-button>
			  </template>
			</el-table-column>
		</el-table>
		
		<el-dialog
		  :close-on-click-modal="false"
		  :close-on-press-escape="false"
		  :visible.sync="showModal"
		  title="选择配件信息"
		  width="80%"
		  v-loading="loading"
		>
		  <el-table
		    :data="addData"
		    style="margin-bottom: 40px; width: 100%"
		    row-key="id"
		    border
		    v-loading="loading"
		    @selection-change="handleSelectionChange"
		  >
		    <el-table-column width="50" type="selection" />
		    <el-table-column
		      show-overflow-tooltip
		      prop="code"
		      label="料号"
		      width="150"
		    />
		    <el-table-column show-overflow-tooltip prop="name" label="名称" />
		    <el-table-column show-overflow-tooltip prop="spec" label="规格" />
		  </el-table>
		
		  <span slot="footer" class="dialog-footer">
		    <el-button type="primary" @click="submit">确认</el-button>
		    <el-button @click="showModal = false">
		      取消
		    </el-button>
		  </span>
		</el-dialog>
	</Page>
</template>

<script>
export default {
	data() {
		return {
			loading: false,
			options: [],
			tableData: [],
			params: {
				sn: '',
				pageNum: 1,
				pageSize: 20
			},
			showModal: false,
			addData: [],
			selected: []
		}
	},
	methods: {
		statusFilter(value) {
			const obj = { 1: '正常', 0: '现已禁用' }
			return obj[value] || value || '-'
		},
		showFilter(value) {
			const obj = { 1: '可见', 0: '隐藏' }
			return obj[value] || value || '-'
		},
		async listSn(keyword) {
			try {
				if (keyword.length > 6) {
					this.loading = true
					this.params.sn = keyword
					const options = await this.$api.bom.pageSnByKey(this.params)
					this.options = options.map(option => {
						return {
							value: option,
							label: option
						}
					})
					console.log(this.options)
				} else {
					this.options = []
				}
			} finally {
				this.loading = false
			}
		},
		async getData() {
			try {
				this.loading = true
				const sn = this.params.sn
				if (sn.length == 0) {
					this.$message.error('请先选择序列号')
				} else {
					this.tableData = await this.$api.bom.listAssetBom(
						this.params
					)
				}
			} catch (err) {
				console.log(err)
			} finally {
				this.loading = false
			}
		},
		async addEvent() {
			const sn = this.params.sn
			if (sn.length == 0) {
				this.$message.error('请先选择序列号')
				return
			}
			this.addData = await this.$api.bom.getBomDetailBySn(
				this.params
			)
			this.showModal = true
		},
		async delEvent(id) {
			const sn = this.params.sn
			let result = await this.$api.bom.deleteAssetBom(id, sn)
			if (result) {
				this.$message.success('删除成功')
			} else {
				this.$message.error('删除失败')
			}
			this.getData()
		},
		handleSelectionChange(val) {
			this.selected = val;
		},
		async submit() {
			if (this.selected.length == 0) {
				this.$message.error('请先选择要添加的配件')
				return
			}
			let bomDetailIds = this.selected.map(item => item.id)
			await this.$api.bom.addToAssetBom(
				{
					sn: this.params.sn,
					bomDetailIds: bomDetailIds.join()
				}
			)
			this.getData()
			this.showModal = false
		},
		tableRowClassName({ row, rowIndex }) {
		    if (row.increment) {
		      return 'danger-row'
		    }
		    return ''
		}
	}
}
</script>
